* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html, body, #root {
  height: 100%;
  font-family: 'Public Sans', sans-serif;
}

.tooltipCustom {
  position: relative;
  display: inline-block;
}

.tooltipCustom .tooltiptextCustom {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Posiciona o tooltip acima do texto */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipCustom:hover .tooltiptextCustom {
  visibility: visible;
  opacity: 1;
}