.user-video {
  position: absolute;
  left: 15px;
  bottom: 77px;

  video {
    width: 300px;
    height: auto;
    background: black;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
  }

  @media only screen and (max-width: 900px) {
    top: 15px !important;
    bottom: auto;
  }
}
