.conference-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background: black;
  overflow-x: hidden;

  .video-layout-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: black;
    align-items: center;
    .layout-call {
      width: 88vw;
      max-height: calc(100vh - 64px);
      margin-bottom: 64px;
      position: relative;
      right: auto;
      left: 0;

      &.expand {
        width: 80vw;
      }
      @media screen and (max-width: 442px) {
        width: 100vw;
      }

    }
  }

  .users-layout-container {
    &.expand {
      width: 20.1vw;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 18px;
    }

    &::-webkit-scrollbar-thumb {
      background: #77787a;
      border-radius: 12px;
    }

    .users-layout-content {
      display: flex;
      flex-direction: column;
      background: transparent;
      &.expand {
        flex-direction: row;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      align-items: center;
      justify-content: center;
      .loading-text {
        margin-top: 10vh;
        color: #a1a1a1;
      }

      @media screen and (max-width: 442px) {
        flex-direction: row;
        justify-content: space-evenly;
         width: 100%;
      }
    }
   
      width: 13vw;
   
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 64px);
      padding: 40px 2px 2px 2px;
      background-color: #26292c;
      overflow-y: auto;
      position: absolute;
      right: 0;
      top: 0;

    @media screen and (max-width: 442px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      background-color: #26292c;
      overflow-y: hidden;
      overflow-x: auto;
      position: absolute;
      bottom: 64px;
      justify-content: space-evenly;
       width: 100%;
       height: 90px;
       top: calc(100% - 154px);
    }

  }

  .chat-open {
    height: 100vh;
    display: block;
  }

  .chat-close {
    display: none;
  }
}

.advisor {
  display: flex;
  flex-direction: row;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 12vw);
  height: 50px;
  background-color: #343758;
  color: #fff;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding: 4px 0px 4px 0px;
  @media only screen and (max-width: 1180px) {
    flex-direction: column;
  }
  .advisor-button {
    position: absolute;
    right: 0px;
    border: 1;
    border-width: 1px;
    border-color: #fff;
    width: 80px;
    background-color: transparent;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
  &.expand {
    width: calc(100% - 20.1vw);
  }
  #text1 {
    margin-left: 6px;
    font-weight: bold;
  }
  #text2 {
    margin-left: 6px;
  }
  #text3 {
    margin-left: 6px;
    text-decoration: underline;
  }
}
