@mixin keyframe ($animation_name) {
  @-webkit-keyframes #{$animation_name} {
      @content;
  }

  @-moz-keyframes #{$animation_name} {
      @content;
  }

  @-o-keyframes #{$animation_name} {
      @content;
  }

  @keyframes #{$animation_name} {
      @content;
  }
}

@mixin animationFadeIn ($animation) {
  animation: $animation 500ms;
}

@mixin animation ($delay, $duration, $animation, $direction: forward, $fillmode: fowards) {
  // -webkit-animation-delay: $delay;
  // -webkit-animation-duration: $duration;
  // -webkit-animation-name: $animation;
  // -webkit-animation-fill-mode: $fillmode;
  // -webkit-animation-direction: $direction;

  -webkit-animation: $animation $duration infinite linear;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $fillmode;
  -moz-animation-direction: $direction;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fillmode;
  animation-direction: $direction;
}