@import '../../styles/mixins.scss';

@include keyframe(rotate) {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.input-button-join {
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 10px;

  input {
    padding-left: 15px;
    width: 250px;
    height: 44px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }

  button {
    background: rgba(234, 226, 236, 255);
    color: rgb(36, 36, 36);
    font-size: 16px;
    border: none;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    flex: 1;
    height: 44px;
    transition: 0.3s;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .loader {
      height: 0;
      width: 0;
      padding: 7px;
      border: 3px solid transparent;
      border-right-color: #fff;
      border-left-color: #fff;
      border-radius: 50%;
      @include animation(0, 1.5s, rotate, infinite);
      left: 50%;
      top: 50%;
    }
  }

  .loading {
    background: #3b3f42;
    cursor: no-drop;

    &:hover {
      background: #2e3335;
    }
  }
}
