.meet-config-button {
  position: relative;
  margin: 0 7px;

  svg {
    color: #29434e;
  }

  .actions-button {
    background: #fff;
    position: absolute;
    right: -5px;
    top: 2px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: #e4e4e4;
    }
  }

  .dropdown-videocam {
    bottom: 62px;
    right: -20px;
    position: absolute;
    width: 230px;
    max-height: 370px;
    background: #2e3335;
    border-radius: 7px;
    transition: 0.2s;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      background-color: #f2f6f9;
      width: 16px;
      opacity: 0;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f2f6f9;
      border-radius: 7px;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #f2f6f9;
      border-radius: 7px;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid #f2f6f9;
      opacity: 0;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    &::-webkit-scrollbar-track-piece {
      opacity: 0.2;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }

    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      border-style: solid;
      border-color: #fff transparent;
      border-width: 8px 8px 0 8px;
      content: '';
      display: none;
      top: 100%;
      position: absolute;
      right: 9px;
      transform: translateX(-50%);
    }

    .videocam-item {
      position: relative;
      cursor: pointer;

      .device-title {
        font-size: 14px;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // min-width: 100%;
        // min-height: 100%;
        width: 180px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.2s;

        &:hover {
          background: rgba(0, 0, 0, 0.6);
        }
      }

      .active {
        background: #003c8f;
        color: #fff;
        width: 200px;
      }
    }
    ul {
      list-style: none;

      .item-section {
        padding: 10px 5px;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;

        svg {
          color: #fff;
          margin-right: 5px;
          font-size: 18px;
        }
      }

      .audio-item {
        padding: 10px 20px;
        color: #999;
        font-size: 15px;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          background: #003c8f;
          color: #fff;
        }
      }
      .active {
        background: #003c8f;
        color: #fff;
      }
    }
  }
}

.dropdown-videocam-header{
  display: flex;
  width: 100%;
  padding-left: 8px;
  padding-bottom: 8px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #2e3335;
  border: 1px solid #2e3335;
  padding: 10px 15px;
  cursor: pointer;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 45px;
  width: 75px;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: none;
}

.dropdown-content.active {
  display: block;
  bottom: 55px;
}

.dropdown-item {
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #eee;
}

.dropdown-item.active {
  background-color: #ddd;
  font-weight: bold;
}

.bonitin{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.dropdown-arrow{
  color: white !important;
  position: absolute;
  left: 5px;
  top: 12px;
}

.dropdown-videocam-divider{
  height: 1px;
  background-color: #fff;
  width: 110%;
}