@import "../../styles/mixins.scss";

@include keyframe(rotate) {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.loader {
  height: 0;
  width: 0;
  padding: 15px;
  border: 7px solid transparent;
  border-right-color: #003c8f;
  border-left-color: #003c8f;
  border-radius: 50%;
  @include animation(0, 1.5s, rotate, infinite);
  // position: absolute;
  left: 50%;
  top: 50%;
}