.container-dropdown {
  margin-bottom: 25px;
}
.dropdown-items {
  margin-bottom: 25px;
  position: fixed;
  width: 108px;
  // height: auto;
  background: #5f6367;
  border-radius: 7px;
  transition: 0.2s;
  padding: 3px 0;
  display: inline-flex;
  flex-direction: column;

  .flag {
    margin: 3px 3px 0px 3px;
  }
  .items-li-dropdown {
    list-style: none;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    height: 100%;

    cursor: pointer;
    color: white;

    &:hover {
      background: rgb(148, 147, 146);
      border-radius: 7px;
    }
  }
}
