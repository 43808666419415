@import '../../styles/mixins.scss';

@include keyframe(transform) {
  from {
    transform: translateX(350px);
  }
  to {
    transform: translateX(0px);
  }
}

.chat-container {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;

  @include animationFadeIn(transform);
  transition: 0.2s;
  right: 0%;
  height: 91.6%;
  border-radius: 12px;
  width: 450px;

  @media only screen and (max-width: 442px) {
    width: 100%;
    height: 84.6%;
  }

  background: #f2f6f9;

  .chat-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;

    span {
      margin: 0;
      color: #60636a;
      font-size: 24px;
    }

    svg {
      font-size: 28px;
      color: rgb(173, 173, 173);
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        color: rgb(136, 136, 136);
      }
    }
  }

  .chat-container-body {
    height: 100%;
    padding: 0 25px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: #f2f6f9;
      width: 16px;
      opacity: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: #f2f6f9;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #f2f6f9;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid #f2f6f9;
      opacity: 0;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    &::-webkit-scrollbar-track-piece {
      opacity: 0.2;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .chat-container-footer form {
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;

    textarea {
      width: 100%;
      border-radius: 7px;
      min-height: 40px;
      border: none;
      margin: 0 10px;
      padding: 10px;
      background-color: #dee3f7;

      font-family: inherit;
      font-size: inherit;
      resize: none;
    }

    .attachment-button {
      margin: 0 15px 0 5px;
      color: #5b6cac;
      cursor: pointer;
      transition: 0.2s;
      font-size: 20px;

      &:hover {
        color: #4c5c94;
      }
    }

    button {
      border-radius: 7px;
      border: none;
      background: #6b7ec2;
      color: #fff;
      width: 50px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background: #5b6cac;
      }
    }

    .input-file {
      display: none;
    }
  }
}
