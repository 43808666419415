.message-container {
  display: flex;
  margin: 10px 0;

  &.my-message {
    justify-content: flex-end;

    .message {
      border-bottom-right-radius: 0;
      background: #dee3f7;
      max-width: 280px;
      word-break: break-word !important;
      white-space: pre-wrap !important;
      word-wrap: break-word !important;
    }
  }

  &.another-message {
    justify-content: flex-start;

    .message {
      border-bottom-left-radius: 0;
      background: #bdbdbd;
      max-width: 280px;
      word-break: break-word !important;
      white-space: pre-wrap !important;
      word-wrap: break-word !important;
    }
  }

  .message {
    border-radius: 12px;
    width: 80%;
    padding: 15px 20px;
    max-width: 280px;
    word-break: break-word !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    .image-container {
      width: 100%;
      position: relative;
      img {
        margin-top: 5px;
        width: 100%;
      }

      .actions {
        position: absolute;
        left: 0;
        top: 5px;
        width: 100%;
        height: 95%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          background: rgba($color: #000000, $alpha: 0.4);
        }

        svg {
          font-size: 22px;
          transition: 0.2s;
          color: #fff;
        }
      }
    }

    .file-container {
      a {
        text-decoration: none;
        color: #000;
        margin-top: 10px;
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        transition: 0.2s;

        svg {
          margin-right: 5px;
        }

        &:hover {
          color: #5b6cac;
        }
      }
    }

    span {
      font-size: 14px;
      font-weight: bold;
    }

    pre {
      margin-top: 5px;
      font-size: 14px;
      font-family: 'Public Sans', sans-serif;
    }

    .message-footer {
      margin-top: 3px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
