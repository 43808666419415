.conference-users-joined {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .conference-users-avatars {

    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }

    img + img {
      margin-left: -10px;
    }
  }

}