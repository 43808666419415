@import '../../styles/mixins.scss';

@include keyframe(pulse) {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(239, 71, 111, 0.4);
    box-shadow: 0 0 0 0 rgba(239, 71, 111, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(239, 71, 111, 0);
    box-shadow: 0 0 0 10px rgba(239, 71, 111, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(239, 71, 111, 0);
    box-shadow: 0 0 0 0 rgba(239, 71, 111, 0);
  }
}

.video-actions-container-bottom {
  z-index: 1;
  opacity: 1;
  background-color: #202125;
  position: absolute;
  bottom: 0;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 64px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .firstContainer {
    position: relative;
    margin-left: 6%;
    text-align: center;
    font-size: 12px;
  }
  .secondContainer {
    .buttonDropdown {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      height: 20px;
      width: 20px;
      border-width: 0px;
      background-color: #4e4e4e;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
      z-index: 100;
      &:hover {
        background-color: #2d2d2d;
      }
      // &.camera {
      // }
      // &.microphone {
      // }
    }
    .button-call-hands-up-green-toggle {
      cursor: pointer;
      background: #3b3f42;
      box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      margin: 2px 6.5px 0px 7px;
      transition: 0.2s;
      svg {
        font-size: 20px;
      }

      &:hover {
        background-color: #2e3335;
      }
      .newMessagePing {
        background-color: red;
      }
    }

    #upHand.show-list {
      &::after {
        border-style: solid;
        border-color: #fff transparent;
        border-width: 8px 8px 0 8px;
        content: '';
        top: -12px;
        position: absolute;
        left: 22px;
        transform: translateX(-50%);

        .before-ocult & {
          display: none;
        }
      }
    }

    .uphand-container-body {
      max-height: 450px;
      padding: 0 18px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 16px;
        opacity: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: #3b3f42;
      }
      &::-webkit-scrollbar-track:hover {
        background-color: #2e3335;
      }

      /* scrollbar itself */
      &::-webkit-scrollbar-thumb {
        background-color: #3b3f42;
        border-radius: 16px;
        border: 5px solid #2e3335;
        opacity: 0;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
        border: 0px solid transparent;
      }

      &::-webkit-scrollbar-track-piece {
        opacity: 0.2;
      }

      /* set button(top and bottom of the scrollbar) */
      &::-webkit-scrollbar-button {
        display: none;
      }
    }

    .text-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .icon-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      width: 50px;
      height: 50px;
  
      background: #3b3f42;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;
  
      &:hover {
        background: #2e3335;
      }
    }
  
    .icon-button.no-config {
      background: #ef476f;
      svg {
        color: #e4e4e4;
      }
  
      &:hover {
        background: #c43b5b;
      }
    }
    .icon-button.no-found {
      background: #c43b5b;
      svg {
        color: #e4e4e4;
      }
  
      &:hover {
        background: #c43b5b;
      }
    }

    .tooltipCustom {
      position: relative;
      display: inline-block;
    }
    
    .tooltipCustom .tooltiptextCustom {
      visibility: hidden;
      width: 120px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 125%; /* Posiciona o tooltip acima do texto */
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
    }
    
    .tooltipCustom:hover .tooltiptextCustom {
      visibility: visible;
      opacity: 1;
    }

    .actions-button {
      background: #3b3f42;
      position: absolute;
      right: -5px;
      top: 2px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
      cursor: pointer;
      transition: 0.2s;
  
      &:hover {
        background: #2e3335;
      }
    }

  }
  .thirdContainer {
    margin-right: 20px;
  }

  @media only screen and (max-width: 900px) {
    padding: 10px;
  }

  /* &.show {
    opacity: 1;
  } */

  .button-call-action {
    cursor: pointer;
    background: #3b3f42;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 47px;
    width: 47px;
    transition: 0.2s;
    margin: -5px 6px;

    svg {
      font-size: 20px;
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      background: #2e3335;

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }

    &.settings {
      cursor: none;
    }
  }

  .button-chat {
    position: relative;

    .chat-badge {
      position: absolute;
      width: 13px;
      height: 13px;
      background: #e9473e;
      border-radius: 50%;
      top: 0;
      right: 0;

      @include animation(0, 1.5s, pulse, infinite);
    }
  }

  .button-call-action.active {
    background: #e9473e;
    position: relative;
    top: 1.8px;

    svg {
      font-size: 24px;
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      background: #e63127;

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
  }

  .end-call {
    background: #e9473e;
    svg {
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      background: #e63127;

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
  }

  .dropdown-button {
    position: relative;
  }

  .dropdown-layouts {
    bottom: 67px;
    left: 12px;
    &.hands {
      left: 10px;
    }
    &.layouts {
      left: -100px;
    }
    position: absolute;
    width: 205px;
    height: auto;
    background: #3b3f42;
    border-radius: 7px;
    transition: 0.2s;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    // align-items: center;

    &.squad {
      &::before {
        left: 243px;
      }
    }

    ul {
      list-style: none;
      cursor: default;
      .layout-item {
        padding: 10px 20px;
        color: #999;
        font-size: 15px;
        cursor: pointer;
        transition: 0.2s;
        &.users {
          cursor: default;
          pointer-events: none;
        }
        &:hover {
          background: #003c8f;
          color: #fff;
        }
      }
      .active {
        background: #003c8f;
        color: #fff;
      }
    }

    @media only screen and (max-width: 700px) {
      bottom: 66px;
      left: 140px;
      &::before {
        left: 125px !important;
      }
    }
  }
}

.video-actions-container-top-right {
  opacity: 1;
  /* &.show {
    opacity: 1;
  } */
  position: absolute;
  width: 230px;
  height: 56px;
  top: -1px;
  right: 0px;
  transition: 0.2s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 2%;
  .button-call-action {
    cursor: pointer;
    background: #3b3f42;
    border: none;
    border-radius: 50%;
    height: 42px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    width: 42px;
    transition: 0.2s;
    margin: 0px;
    svg {
      font-size: 20px;
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
  }
  .button-call-action.active {
    background: #e9473e;

    svg {
      color: rgba(234, 226, 236, 255);
    }

    &:hover {
      background: #e63127;

      svg {
        color: rgba(234, 226, 236, 255);
      }
    }
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  .dropdown-layouts {
    position: absolute;
    width: 231.5px;
    height: auto;
    background: rgb(248, 248, 248);
    border-radius: 4px;
    transition: 0.2s;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.2);
    cursor: default !important;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    // align-items: center;

    ul {
      list-style: none;
      .layout-item {
        padding: 10px 20px;
        color: #999;
        font-size: 15px;
        transition: 0.2s;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &.hands-up {
          background: #003c8f;
          color: #fff;
          &:hover {
            background: #003c8fd8;
            color: #fff;
            cursor: pointer;
          }
        }

        .green {
          margin-left: 5px;
        }
      }

      .active {
        background: #003c8f;
        color: #fff;
      }
    }
  }
}

.d-none {
  display: none;
}

.divider {
  background-color: rgb(219, 218, 218);
  height: 80%;
  width: 1.2px;
  margin: 0px 10px 0px 10px;
}

.newMessagePing {
  background-color: red;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  position: absolute;
  top: 7.5px;
}

.newMessageNumber {
  text-align: center;
  justify-content: center;
  color: rgb(219, 218, 218);
  font-size: 9px;
  margin-top: 2px;
  margin-left: 1px;
  padding: 0px;
  font-weight: bold;
}
.containerDropdown {
  position: fixed;
  margin-top: 22px;
  left: 15px;
}
.textName {
  max-width: 10ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
